import React, {Component} from 'react';
import "./styles/tailwind.css";
import './App.css';
import Header from './Components/partials/header/Header';
import Footer from './Components/partials/footer/Footer';
//Important
import axios from 'axios';
axios.defaults.baseURL = 'https://ecombackend.devpappu.com/api/';
// axios.defaults.baseURL = 'http://ecommerce.test/api/';
window.$apiUrl = 'https://ecombackend.devpappu.com' //global variable
// window.$apiUrl = 'http://ecommerce.test' //global variable

class App extends Component {

  render() {
    return (
      <>
      <Header/>
      <Footer/>
      </>
    );
  }
}

export default App;


