// import React, {Component} from 'react';
import React, {useState, useEffect} from 'react';

import { BrowserRouter as Router, Switch, Route,} from "react-router-dom";
import ScrollToTop from '../../Common/ScrollToTop';
import Nav from './nav';
import Home from "../../pages/home";
import Post from "../../pages/post/Post";
import Create from "../../pages/post/create/Create";
import Single from "./../../pages/post/single/single";
import Products from "../../pages/products/shop/Product";
import ProductSingle from "../../pages/products/single/Single";
import Login from "../../auth/login";
import axios from 'axios';
import Profile from "../../pages/profile/profile";
import Crud from "../../pages/crud/index";
import ReduxComponent from "../../pages/redux/Redux";
import Apishop from "../../pages/apishop/product";
import Apishopsingle from "../../pages/apishop/single";
import Test from "../../Test";
import Cart from './../../pages/Ecommerce/Cart/Cart'
import Checkout from '../../pages/Ecommerce/Checkout/Checkout';
const Header =() => {

    const [user, setUser] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('token')

        if(token){
           axios.get(`user`,{ 
             headers: {"Authorization" : `Bearer ${token}`} 
          }).then(res =>{
            UserSet(res.data)
           })
        }

    }, []);


    const UserSet = (user) =>{
      setUser(user)
    }

      return (
      
        <Router>
          <ScrollToTop/>
           <Nav user={user} setUser={UserSet}/>
            <div className="layout__padding">
                <Switch>
                    <Route exact path='/' component={ ()=><Home user={user}/> }/>
                    <Route exact path='/posts' component={Post} />
                    <Route exact path='/products' component={Products} />
                    <Route exact path='/product/:slug' component={ProductSingle} />
                    <Route exact path='/posts/:id' component={Single} />
                    <Route exact path='/create' component={Create} />
                    <Route exact path='/login' component={()=><Login setUser={UserSet}/>}/>
                    <Route exact path='/profile' component={()=><Profile user={user}/>}/>
                    <Route exact path='/crud' component={Crud}/>
                    <Route exact path='/redux' component={ReduxComponent}/>
                    <Route exact path='/fakestore' component={Apishop}/>
                    <Route exact path='/fakestore/:id' component={Apishopsingle}/>
                    <Route exact path='/cart' component={Cart}/>
                    <Route exact path='/test' component={Test}/>
                    <Route exact path='/checkout' component={Checkout}/>
                </Switch>
            </div>
            
     </Router>

      );
    
  }

  export default Header