import React, {Component} from 'react';
import {Link} from "react-router-dom";
// import Logo from "../../../assets/logo.png";
import './Nav.css'
export default class nav extends Component {

    state = { isActive: false };

   logout = () =>{
    localStorage.clear();
    this.props.setUser(null);
   }

   phone_icon = () =>{
       alert('hello') 
   }

   handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
   
    render() {

        const isActive = this.state.isActive;
        let profile;
        const auth = localStorage.getItem('token')
       
        if(auth){
            profile=(

                <div>
                    <Link className="primari__color text-white py-2 px-3 rounded-lg" to={"/profile"}>
                    Profile</Link>

                    <Link onClick={this.logout} className="primari__color text-white ml-4 py-2 px-3 rounded-lg" to={"/"}>
                    Logout</Link>
                </div>
            )
        }else(
            profile=(
                <Link  className="bg-indigo-700 hover:bg-indigo-500 text-white ml-4 py-2 px-3 rounded-lg" to={"/login"}>
                    Login
                </Link>
            )
        )

      return (
          
        <div className="header-nav">

            <header className="text-black body-font bg-white shadow py-5">
                <div className="layout__padding items-center">
                    
                    <div className="phone__menu">

                        <div className="flex justify-between items-center">
                        <div className="phone__logo">
                            <Link to={"/"}> <h1 className="text-3xl">LOGO</h1> </Link>
                        </div>

                        <div className="nav__icon">
                            <i onClick={this.handleToggle} class="nav__icon las la-bars"></i>
                        </div>
                        </div>

                    </div>

                    <div className={`nav__menus lg:flex md:flex justify-between items-center ${isActive ? "active" : ""}`}>
                        
                        <ul className="lg:flex md:flex gap-5">
                            <li className="menu__item">
                            <Link onClick={this.handleToggle}
                                className="mr-5  cursor-pointer border-b  border-transparent hover:border-indigo-600" to={"/"}>Home</Link>
                            </li>
                            <li className="menu__item">
                            <Link
                               onClick={this.handleToggle} className="mr-5 cursor-pointer border-b  border-transparent hover:border-indigo-600" to={"/products"}>Shop</Link>
                            </li>
                            <li className="menu__item">
                            <Link className="mr-5 menu__item cursor-pointer border-b  border-transparent hover:border-indigo-600" to={"/fakestore"}>
                            Fakestore</Link>
                            </li>
                            <li className="menu__item">
                            <Link onClick={this.handleToggle} className="mr-5 menu__item cursor-pointer border-b  border-transparent hover:border-indigo-600" to={"/cart"}>
                            Cart</Link>
                            </li>
                            <li className="menu__item">
                            <Link className="mr-5 menu__item cursor-pointer border-b  border-transparent hover:border-indigo-600" to={"/cart"}>
                            Contact Us</Link>
                            </li>
                        </ul>

                        <div className="inline-flex md:ml-5 lg:ml-0">
                          {profile}
                        </div>
                    </div>
                    
                </div>
          </header>

        </div>
      );
    }
  }

