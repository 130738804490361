import axios from "axios"
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Products from '../Checkout/Micro_Components/Products'
const Checkout = (url) =>{

    const [Form, setFoem] = useState({
        name: '',
        address: '',
        city: '',
        email: '',
        phone: '',
        products: '',
        zip_code: '',
        country: '',
        state: '',
        error_list: [],
    });

    const checkout = () =>{
        axios.post(`checkout`, Form).then(res =>{
            console.log(res)
        })
    }


    const handleChange = (e) => {
        e.persist();
        setFoem({...Form, [e.target.name]: e.target.value })
    }

    return (
        <div className="Checkout mt-10 layout__padding">
            <form>
                <div className="grid lg:grid-flow-col gap-10  py-16">
                    <div className="py-6 px-8 bg-white rounded shadow-xl">
                        
                        <div className="form__group mb-6 grid gap-3 grid-flow-col">
                            <div className="mb-6">
                                <label className="block text-gray-800 font-bold mb-2">Name</label>
                                <input type="text" name="name" id="name" placeholder="name"
                                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                    onChange={handleChange} value={Form.name} />
                            </div>

                            <div className="">
                                <label className="block text-gray-800 font-bold mb-2">email</label>
                                <input type="email" name="email" id="email" placeholder="email"
                                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                    onChange={handleChange} value={Form.email} />
                            </div>
                        </div>

                        <div className="form__group mb-6 grid gap-3 grid-flow-col">
                            <div className="">
                                <label className="block text-gray-800 font-bold mb-2">phone</label>
                                <input type="phone" name="phone" id="phone" placeholder="phone"
                                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                    onChange={handleChange} value={Form.phone} />
                            </div>

                            <div className="mb-6">
                                <label className="block text-gray-800 font-bold mb-2">city</label>
                                <input type="text" name="address" id="address" placeholder="address"
                                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                    onChange={handleChange} value={Form.address} />
                            </div>
                        </div>

                       
                        <div className="form__group mb-6 grid gap-3 grid-flow-col">
                            <div className="">
                                <label className="block text-gray-800 font-bold mb-2">state</label>
                                <input type="text" name="state" id="state" placeholder="state"
                                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                    onChange={handleChange} value={Form.state} />
                            </div>

                            <div className="">
                                <label className="block text-gray-800 font-bold mb-2">zip_code</label>
                                <input type="text" name="zip_code" id="zip_code" placeholder="zip_code"
                                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                    onChange={handleChange} value={Form.zip_code} />
                            </div>

                        </div>

                        <div className="form__group mb-6 grid gap-3 grid-flow-col">
                            <div className="">
                                <label className="block text-gray-800 font-bold mb-2">city</label>
                                <input type="text" name="city" id="city" placeholder="city"
                                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                    onChange={handleChange} value={Form.city} />
                            </div>

                            <div className="">
                            <label className="block text-gray-800 font-bold mb-2">country</label>
                            <input type="text" name="country" id="country" placeholder="country"
                                className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                                onChange={handleChange} value={Form.country} />
                           </div>
                        </div>

                    </div>
                    <div className="Checkout_item py-6 px-8 bg-white rounded shadow-xl">
                        <h1 className="font-semibold text-lg mb-3">Products</h1>
                        <Products/>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Checkout