const Footer = () =>{
    return (
        <div className="footer layout__padding bg-white mt-16">
              
              <div className="grid lg:grid-flow-col gap-10  py-16">
                 <div className="footer_item">
                     <h1 className="font-semibold text-lg mb-3">SUPPORT CALL CENTER</h1>
                     <ul>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                     </ul>
                 </div>
                 <div className="footer_item">
                     <h1 className="font-semibold text-lg mb-3">QUICK LINKS</h1>
                     <ul>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                     </ul>
                 </div>
                 <div className="footer_item">
                     <h1 className="font-semibold text-lg mb-3">CUSTOMER CARES</h1>
                     <ul>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                     </ul>
                 </div>
                 <div className="footer_item">
                     <h1 className="font-semibold text-lg mb-3">SUPPORT CALL CENTER</h1>
                     <ul>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                         <li>Contact For Order <span>+8801629773987</span></li>
                     </ul>
                 </div>
              </div>
        </div>
    )
}

export default Footer