
const  Profile = (props) => {
    
    const name =  props.user.name
    const email = props.user.email

        return (
            <div className="view-component container px-20 py-10">
                <p>Your Name  {name}</p>
                <p>Your Email  {email}</p>
            </div>
        );
}

export default Profile;
// window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
