import React, {useState} from 'react';
import Swal from 'sweetalert2';
import {useHistory} from 'react-router-dom';

import axios from 'axios';

const  Login = (props) => {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

     const history = useHistory();
     const [Form, setForm] = useState({
        email: '',
        password: '',
        errors:[],
    });
     
     const  handleChange = (e) => {

        e.persist();
        setForm({...Form, [e.target.name]: e.target.value })
     }

     const  onSubmit = (e) => {
        e.preventDefault();
        
            axios.post(`user-login`, Form).then(response =>{
                
                // const token = response.data.access_token;
                const token = response.data.token;

                setForm({...Form, errors: response.data.errors});
            
                if(token){
                    const user = response.data.user;
                    localStorage.setItem('token', token);
                    props.setUser(user);
                    history.push(`/profile`)
                    Toast.fire({
                        icon: 'success',
                        title: 'Login Success'
                      })
                }
            })
            .catch(e =>{

                Toast.fire({
                    icon: 'error',
                    title: e.response.data.errors.email
                  })
            })

        }

        return (
            <div className="lg:flex justify-center">
            
                <div className="py-6 px-8 my-20 bg-white rounded shadow-xl lg:w-5/12">
                    <form onSubmit={onSubmit}>

                        <div className="mb-6">
                            <label className="block text-gray-800 font-bold">Email</label>
                            <input type="email" name="email" id="email" placeholder="email" className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                            onChange={handleChange} 
                            value={Form.email}
                            />
                        </div>

                        <div className="mb-6">
                            <label className="block text-gray-800 font-bold">Password</label>
                            <input type="password" name="password" id="email" placeholder="password" className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                            onChange={handleChange} 
                            value={Form.password}
                            />
                        </div>

                        
                        <button type="submit" className="cursor-pointer py-2 px-4  mt-5 bg-indigo-500 text-white font-bold rounded">Login Now</button>
                    </form>
                </div>
            </div>

        );
}

export default Login;

