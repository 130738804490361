import iphone from '../../assets/images/iphone.png';
import HomeProducts from '../Micro_Components/HomeProduct';
const Home = () => {
  return (
    <div className="Home">
      
      <div className="grid lg:grid-cols-2  sm:grid-cols-1 gap-6 align-middle items-center">
        <div className="h-screen flex justify-center items-start flex-col">
          <h1 className="lg:text-5xl text-4xl text-black hero__heading">The Best Ecommerce Store For Digital Products</h1>
          <p className="text-black mt-5 text-lg py-8">
            Referring to class, it is impossible not to mention the pima fabric line. It creates the best quality for
            any fashion product. The pima yarn is twice as long and thicker than ordinary cotton yarn thanks to advanced
            weaving technology. That makes the structure of the polo shirt strong, durable, minimizes ruffles, smooth,
            colorfast, extremely ensuring the user's health.
          </p>
          <button className="py-4 px-6 primari__color rounded-lg font-bold text-white mt-5">
            SHOP NOW & GET 50% OFF!
          </button>
        </div>

        <div className="align-right">
          <img src={iphone} alt="" className="hero__img" srcset="" />
        </div>
      </div>

      <div className="mt-5 shop__features grid lg:grid-flow-col gap-10">
       
        <div className="item policy__card shadow-sm bg-white py-4 px-7 flex gap-5 items-center">
           <div className="policy__icon"><i class="las la-shopping-bag"></i></div>
           <div className="text">
             <h4 className="feature_item__title">Free Shpping</h4>
              <p className="">Free shpping over $500</p>
           </div>
        </div>
        <div className="item policy__card shadow-sm bg-white py-4 px-7 flex gap-5 items-center">
           <div className="policy__icon"><i class="las la-credit-card"></i></div>
           <div className="text">
             <h4 className="feature_item__title">Cod Payment</h4>
              <p className="">Payment after delivery</p>
           </div>
        </div>
        <div className="item policy__card shadow-sm bg-white py-4 px-7 flex gap-5 items-center">
           <div className="policy__icon"><i class="las la-truck"></i></div>
           <div className="text">
             <h4 className="feature_item__title">Fast Delivery</h4>
              <p className="">Fast delivery within one week</p>
           </div>
        </div>
        <div className="item policy__card shadow-sm bg-white py-4 px-7 flex gap-5 items-center">
           <div className="policy__icon"><i class="las la-hand-holding-heart"></i></div>
           <div className="text">
             <h4 className="feature_item__title">27/4 Live Support</h4>
              <p className="">Fast support </p>
           </div>
        </div>
      </div>

      <div className="products__section my-24">
        <div className="title font-semibold text-3xl text-center">
          <h1>Top Selling Product Of The Month</h1>
        </div>

        <div className="mt-5 products">
           <HomeProducts/>
        </div>
      </div>

    </div>

   
  )
}

export default Home;
